import axios from '../service/axios'
export default {
  // 获取店铺商品列表
  shopGoodsLists(data) {
    return axios({
      method: 'post',
      url: '/store/Shopcoupon/ShopGoodsLists',
      data,
    })
  },
  // 获取店铺商品列表
  addOrEditoGoodsLists(data) {
    return axios({
      method: 'post',
      url: '/store/Shopactivity/shopgoodslists',
      data,
    })
  },
  // 创建优惠券
  createCoupon(data) {
    return axios({
      method: 'post',
      url: '/store/Shopcoupon/createCoupon',
      data,
    })
  },
  // 编辑优惠券
  editCoupon(data) {
    return axios({
      method: 'post',
      url: '/store/Shopcoupon/editCoupon',
      data,
    })
  },
  // 获取优惠券详情
  getCouponInfo(data) {
    return axios({
      method: 'post',
      url: '/store/Shopcoupon/getCouponInfo',
      data,
    })
  },
  //  获取优惠券统计数据
  getcouponstatisticaldata(data) {
    return axios({
      method: 'post',
      url: '/store/shopcoupon/getcouponstatisticaldata',
      data,
    })
  },
  //   获取优惠券统计折线图数据
  getCouponLineStatisticalData(data) {
    return axios({
      method: 'post',
      url: '/store/shopcoupon/getCouponLineStatisticalData',
      data,
    })
  },
}
