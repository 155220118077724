<template>
  <div class="big container">
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="活动商品" prop="goods_id">
        <el-button
          type="primary"
          @click="chooseGoods"
          style="margin-bottom: 5px;"
          :disabled="isEdit ? true : false"
          >选择商品</el-button
        >
        <el-table
          ref="multipleTable"
          :data="form.goods_list"
          tooltip-effect="dark"
          style="width: 100%;min-width: 800px"
          :header-cell-style="{ background: '#F5F5F5' }"
        >
          <el-table-column label="商品" prop="goods_name"> </el-table-column>
          <el-table-column label="规格" prop="sku_name"> </el-table-column>
          <el-table-column label="价格" prop="price"> </el-table-column>
          <el-table-column label="当前库存" prop="stock"> </el-table-column>
          <el-table-column label="拼团价">
            <template slot-scope="scope">
              <el-form-item
                :prop="'goods_list.' + scope.$index + '.promote_price'"
                :rules="rules.promote_price"
              >
                <el-input-number
                  v-model="scope.row.promote_price"
                  step-strictly
                  :precision="2"
                  :step="0.01"
                  :controls="false"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="活动限量" prop="limit_sale">
            <template slot-scope="scope">
              <el-form-item
                :prop="'goods_list.' + scope.$index + '.limit_sale'"
                :rules="rules.limit_sale"
              >
                <el-input-number
                  v-model="scope.row.limit_sale"
                  step-strictly
                  :step="1"
                  :controls="false"
                />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="活动时间" prop="limit_time">
        <el-date-picker
          v-model="form.limit_time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
        <span style="color: #999; margin-left: 5px"
          >超过限售时间时，商品自动下架</span
        >
      </el-form-item>
      <el-form-item label="参团人数" prop="group_num">
        <el-input-number
          v-model="form.group_num"
          step-strictly
          :step="1"
          :controls="false"
          placeholder="建议2-10人"
        >
        </el-input-number>
        <span style="margin: 0 3px;">人</span>
      </el-form-item>
      <el-form-item label="拼团有效期" prop="effective_time">
        <el-input-number
          v-model="form.our"
          step-strictly
          :step="1"
          :controls="false"
          placeholder="请输入"
          style="width: 90px;margin: 0 3px;"
        />
        <span style="margin: 0 3px;">时</span>
        <!-- <el-select
          v-model="form.minutes"
          placeholder="请选择"
          style="width:90px;"
          filterable
          @change="changeTime"
        >
          <el-option
            v-for="item in minutesList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select> -->
        <el-input-number
          v-model="form.minutes"
          step-strictly
          :step="1"
          :controls="false"
          placeholder="请输入"
          style="width: 90px;margin: 0 3px;"
        />
        <span style="margin: 0 3px;">分</span>
      </el-form-item>
      <el-form-item label="自动成团" prop="auto_time">
        <el-radio v-model="form.is_auto" :label="0">不开启</el-radio>
        <el-radio v-model="form.is_auto" :label="1">开启</el-radio
        ><span style="color: #999"
          >距拼团到期前<el-input-number
            v-model="form.auto_timeOur"
            :disabled="form.is_auto != 1"
            step-strictly
            :step="1"
            :controls="false"
            placeholder="请输入"
            style="width: 90px;margin: 0 3px;"
          />
          <span style="margin: 0 3px;">时</span>
          <el-input-number
            v-model="form.auto_timeMinutes"
            :disabled="form.is_auto != 1"
            step-strictly
            :step="1"
            :controls="false"
            placeholder="请输入"
            style="width: 90px;margin: 0 3px;"
          />
          <span style="margin: 0 3px;">分</span>自动成团</span
        >
      </el-form-item>
      <el-form-item label="限购" prop="is_max">
        <el-radio v-model="form.is_max" :label="0">不限购</el-radio>
        <el-radio v-model="form.is_max" :label="1">
          <span>每人限购</span>
          <el-form-item
            label-width="0"
            style="display: inline-block;margin-bottom: 0;"
            prop="max_num"
          >
            <el-input-number
              v-model="form.max_num"
              step-strictly
              :step="1"
              :controls="false"
              :disabled="form.is_max != 1"
              placeholder="请输入"
              style="width: 90px;margin: 0 3px;"
            >
            </el-input-number>
          </el-form-item>

          <span>件</span>
        </el-radio>
      </el-form-item>
      <el-form-item label="凑团" prop="is_group">
        <el-radio v-model="form.is_group" :label="1">开启</el-radio>
        <el-radio v-model="form.is_group" :label="0">不开启</el-radio>
      </el-form-item>
      <el-form-item label="标签显示">
        <el-input
          v-model="form.group_label"
          placeholder="长度不超过6个汉字"
          maxlength="6"
          style="width:200px"
        ></el-input>
        <span style="color: #999; margin-left: 5px"
          >用于用户端商品标签显示，不设置则默认为x人团，x为设置的拼团人数</span
        >
      </el-form-item>
      <el-form-item label="活动提示">
        <el-checkbox v-model="form.show_info"
          >活动未开始时，提前24小时在商品详情页提示用户</el-checkbox
        >
      </el-form-item>
    </el-form>
    <div class="submit flex center align-center">
      <el-button type="primary" @click="addTeamwork" size="medium"
        >确定</el-button
      >
    </div>
    <!-- 选择活动商品 -->
    <ChooseGood
      v-if="chooseGoodShow"
      @close="chooseGoodShow = false"
      @checkGoods="checkGoods"
    ></ChooseGood>
  </div>
</template>
<script>
import ChooseGood from "./ChooseGood";
export default {
  components: {
    ChooseGood,
  },
  data() {
    let checkGroupNum = (rule, value, callback) => {
      if (!value || value > 99 || value < 2) {
        return callback(new Error("请输入参团人数为2-99人"));
      }
      callback();
    };
    let checkTime = (rule, value, callback) => {
      if (
        this.form.our * 60 + this.form.minutes <= 0 ||
        this.form.our == undefined ||
        this.form.minutes == undefined
      ) {
        console.log(1);
        return callback(new Error("请输入 时为0-99,分为0-59"));
      }
      if (this.form.our > 99 || this.form.our < 0) {
        console.log(2);
        return callback(new Error("请输入 时为0-99,分为0-59"));
      }
      if (this.form.minutes > 59 || this.form.minutes < 0) {
        console.log(3);
        return callback(new Error("请输入 时为0-99,分为0-59"));
      }
      callback();
    };
    let checkAutoTime = (rule, value, callback) => {
      if (this.form.is_auto != 1) {
        callback();
      }
      if (
        this.form.auto_timeOur * 60 + this.form.auto_timeMinutes <= 0 ||
        this.form.auto_timeOur == undefined ||
        this.form.auto_timeMinutes == undefined
      ) {
        console.log(1);
        return callback(new Error("请输入 时为0-99,分为0-59"));
      }
      if (this.form.auto_timeOur > 99 || this.form.auto_timeOur < 0) {
        console.log(2);
        return callback(new Error("请输入 时为0-99,分为0-59"));
      }
      if (this.form.auto_timeMinutes > 59 || this.form.auto_timeMinutes < 0) {
        console.log(3);
        return callback(new Error("请输入 时为0-99,分为0-59"));
      }
      callback();
    };
    let checkGoodList = (rule, value, callback) => {
      if (!this.form.goods_list.length) {
        return callback(new Error("请选择商品"));
      }
      callback();
    };
    let checkRelief = (rule, value, callback) => {
      if ((!value || value > 99 || value < 1) && this.form.is_max == 1) {
        return callback(new Error("请输入 1-99件"));
      }
      callback();
    };
    return {
      isEdit: this.$route.query.isEdit,
      group_id: this.$route.query.group_id,
      form: {
        goods_list: "",
        start_time: "",
        end_time: "",
        group_num: undefined,
        effective_time: "",
        is_max: "",
        max_num: undefined,
        is_group: 1,
        group_label: "",
        show_info: "",
        limit_time: "",
        our: undefined,
        minutes: undefined,
        goods_list: [],
        is_auto: 0,
        auto_time: "",
        auto_timeOur: undefined,
        auto_timeMinutes: undefined,
      },
      chooseGoodShow: false,
      ourList: [],
      minutesList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      rules: {
        goods_id: [
          { required: true, validator: checkGoodList, trigger: "blur" },
        ],
        promote_price: [
          { required: true, message: "请输入价格", trigger: "blur" },
        ],
        limit_sale: [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
        limit_time: [
          { required: true, message: "请选择活动时间", trigger: "blur" },
        ],
        group_num: [
          { required: true, validator: checkGroupNum, trigger: "blur" },
        ],
        effective_time: [
          { required: true, validator: checkTime, trigger: "blur" },
        ],
        auto_time: [
          { required: true, validator: checkAutoTime, trigger: "blur" },
        ],
        is_max: [{ required: true, message: "请选择限购", trigger: "blur" }],
        max_num: [{ required: true, validator: checkRelief, trigger: "blur" }],
        is_group: [{ required: true, message: "请选择凑团", trigger: "blur" }],
      },
    };
  },
  created() {
    this.ourList = [...new Array(100).keys()];
    this.minutesList = [...new Array(60).keys()];
    if (this.isEdit) {
      this.getList();
    }
  },
  methods: {
    // 编辑回显
    getList() {
      const data = {
        group_id: this.group_id,
        is_edit: this.isEdit ? 1 : 0,
      };
      this.axios
        .post("/store/Shopactivity/getGroupActivityInfo", data)
        .then((res) => {
          this.form = res.data.info;
          this.form.our = Math.floor(res.data.info.effective_time / 60);
          this.form.minutes = res.data.info.effective_time % 60;
          this.form.auto_timeOur = Math.floor(res.data.info.auto_time / 60);
          this.form.auto_timeMinutes = res.data.info.auto_time % 60;
          this.$set(this.form, "limit_time", [
            res.data.info.start_time * 1000,
            res.data.info.end_time * 1000,
          ]);
          this.form.show_info = this.form.show_info ? true : false;
          console.log("this.form", this.form);
          if (this.form.tip.length) {
            this.$confirm(
              "该商品的商品信息已更新过，请更新同步商品信息",
              "提示",
              {
                confirmButtonText: "我知道了",
                showCancelButton: false,
                type: "warning",
              }
            )
              .then(() => {
                // this.form.goods_list.map((el) => {
                //   el.promote_price = "";
                //   el.limit_sale = "";
                //   return el;
                // });
                // console.log(this.form);
              })
              .catch(() => {});
          }
        });
    },

    // 保存并退出
    addTeamwork() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.form.effective_time = this.form.our * 60 + this.form.minutes;
          this.form.auto_time =
            this.form.auto_timeOur * 60 + this.form.auto_timeMinutes;
          const data = {
            group_id: this.group_id,
            goods_list: this.form.goods_list,
            start_time: this.dayjs(this.form.limit_time[0]).unix(),
            end_time: this.dayjs(this.form.limit_time[1]).unix(),
            group_num: this.form.group_num,
            effective_time: this.form.effective_time,
            is_auto: this.form.is_auto,
            auto_time: this.form.auto_time,
            is_max: this.form.is_max,
            max_num: this.form.max_num,
            is_group: this.form.is_group,
            group_label: this.form.group_label,
            show_info: this.form.show_info,
          };
          console.log(this.form.limit_time);
          console.log("DATA", data);
          this.axios
            .post("/store/Shopactivity/saveGroupActivity", data)
            .then((res) => {
              this.$router.go(-1);
            });
        } else {
          console.log("error submit!!");
          return;
        }
      });
    },
    // 选择商品
    chooseGoods() {
      this.chooseGoodShow = true;
    },
    // 选中商品
    checkGoods(val) {
      console.log(val);
      this.form.goods_list = val[0].item.length > 0 ? val[0].item : val;
    },
  },
};
</script>
<style less="scss" scoped></style>
