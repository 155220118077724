<template>
  <el-dialog
    title="选择商品"
    :visible.sync="dialog"
    width="600px"
    style="text-align:left;"
    :close-on-click-modal="false"
    append-to-body
    class="big"
    :show-close="false"
  >
    <div>
      <div>
        <el-input
          v-model="goods_name"
          placeholder="请输入商品"
          style="width:200px"
        ></el-input>
        <el-button type="primary" @click="getList" style="margin-left:20px"
          >查询</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="list"
        style="width: 100%;"
        height="400"
      >
        <el-table-column label="选择" prop="create_time" width="80">
          <template slot-scope="scope">
            <el-radio v-model="aimId" :label="scope.row.goods_id">{{""}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="全部商品" prop="create_time" width="200">
          <template slot-scope="scope">
            <div style="display: flex;align-items: center;">
              <img
                :src="scope.row.picture_str"
                alt
                style="width:60px;height:60px;border-radius:5px;display: inline-block;margin-right: 5px;"
              />
              <div>{{ scope.row.goods_name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品价格" prop="price"> </el-table-column>
        <el-table-column label="库存" prop="stock"> </el-table-column>
      </el-table>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" plain>取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import discounts from "@/api/discounts";
export default {
  data() {
    return {
      goods_name: "",
      dialog: true,
      aimId: "",
      page: 1,
      total: 0,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  props: {
    act_id: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    getList() {
      let params = {
        act_id: this.act_id,
        goods_name: this.goods_name,
        pageNumber: 1000,
      };
      this.axios
        .post("/store/shopactivity/ShopGroupGoodsLists", params).then((res) => {
        let { data } = res;
        let list = data.list;
        this.list = list;
      });
    },
    close() {
      this.$emit("close");
    },
    submit() {
      let list = this.list.filter((ele) => {
        return ele.goods_id == this.aimId;
      });
      this.$emit("checkGoods", list);
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 30px 30px 0;
  box-sizing: border-box;
}
.list-box {
  height: 400px;
  overflow: auto;
  margin-top: 15px;
  & > div {
    margin-bottom: 10px;
  }
  img {
    height: 70px;
    width: auto;
    margin: 0 15px;
  }
}
</style>
